<template>
  <div class="page-container">
    <!-- <NavbarComponent /> -->
    <div class="main-container">
      <div class="table-container">
        <table class="table table-hoverr">
          <thead>
            <tr>
              <th class="bookmark"></th>
              <th class="number">#</th>
              <th class="name">نام محصول</th>
              <th class="price">قیمت‌بازار‌(تومان)</th>
              <th class="price">قیمت‌بورس</th>
              <th class="price">قیمت‌تمام شده</th>
              <th class="date">تاریخ‌بروزرسانی</th>
              <th class="change">تغییرات</th>
              <th class="chart">هفت‌روز‌گذشته</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in rows" v-bind:key="item">
              <td class="starSvg">
                <StarSvg />
              </td>
              <td>
                <span>
                  {{ index + 1 }}
                </span>
              </td>
              <td>
                <span class="table-name" v-if="item.icon?.length > 10"
                  ><img :src="`data:image/png;base64,${item.icon}`"
                /></span>
                <span>
                  {{ item.name }}
                </span>
              </td>
              <td>
                <span v-if="item.hidePrice === 0">
                  {{ EnToPr(item.latestPrice) }}
                </span>
                <span class="call-button" v-if="item.hidePrice === 1">
                  <button class="btn btn-primary btn-sm">تماس بگیرید</button>
                </span>
              </td>
              <td>
                <span v-if="item.hidePrice === 0">
                  {{ EnToPr(item.latestboorsPrice) }}
                </span>
                <span class="call-button" v-if="item.hidePrice === 1">
                  <button class="btn btn-primary btn-sm">تماس بگیرید</button>
                </span>
              </td>
              <td>
                <span v-if="item.hidePrice === 0">
                  {{ EnToPr(item.latestAllPrice) }}
                </span>
                <span class="call-button" v-if="item.hidePrice === 1">
                  <button class="btn btn-primary btn-sm">تماس بگیرید</button>
                </span>
              </td>
              <td>
                <span> {{ EnToPrNoDate(item.latestCreatedOn) }} </span>
              </td>
              <td>
                <div
                  class="priceup"
                  v-if="compareLastTwoValues(item.prices) && item.prices"
                >
                  <span>
                    {{ EnToPrNo(getPercent(item.prices)) }}
                  </span>
                  <UpSvg />
                </div>
                <div
                  class="pricedown"
                  v-if="!compareLastTwoValues(item.prices) && item.prices"
                >
                  <span>
                    {{ EnToPrNo(getPercent(item.prices)) }}
                  </span>
                  <DownSvg />
                </div>
              </td>

              <td
                v-if="
                  compareLastTwoValues(item.prices) &&
                  item.prices &&
                  JSON.parse(item.prices).length > 1
                "
                class="chart-container green"
              >
                <TrendChart :datasets="[{ data: JSON.parse(item.prices) }]" />
              </td>
              <td
                v-if="
                  !compareLastTwoValues(item.prices) &&
                  item.prices &&
                  JSON.parse(item.prices).length > 1
                "
                class="chart-container"
              >
                <TrendChart :datasets="[{ data: JSON.parse(item.prices) }]" />
              </td>
              <td v-else class="chart-container"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import UpSvg from "@/components/svgs/UpSvg.vue";
import DownSvg from "@/components/svgs/DownSvg.vue";
import StarSvg from "@/components/svgs/StarSvg.vue";
import { ref, onBeforeMount } from "vue";
import moment from "jalali-moment";
import axios from "axios";
import { useToast } from "vue-toastification";
// import NavbarComponent from "../components/NavbarComponent";
import { useRoute } from "vue-router";
import { watch } from "vue";
const route = useRoute();
const rows = ref([]);
const toast = useToast();
const loading = ref(false);
const EnToPr = (number) => {
  if (number === 0) {
    return 0;
  }
  if (number) {
    const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    const formattedNumber = number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const persianNumber = formattedNumber.replace(
      /\d/g,
      (digit) => persianDigits[digit]
    );

    return persianNumber;
  }
};
const searchQuery = ref("");
const getMaterial = async () => {
  if (route.query.q) {
    searchQuery.value = route.query.q;
  } else {
    searchQuery.value = "";
  }
  try {
    loading.value = true;
    if (searchQuery.value.length > 1) {
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_API}/api/materialsome?q=${searchQuery.value}`
      );
      rows.value = data;
    } else {
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_API}/api/materialsome`
      );

      rows.value = data;
    }
    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });

    loading.value = false;
    console.log(err);
  }
};
const EnToPrNoDate = (e) => {
  if (e) {
    const number = moment(e).format("jYYYY/jMM/jDD");
    const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    const formattedNumber = number.toString();
    let persianNumber;

    if (formattedNumber.startsWith("-")) {
      persianNumber =
        "-" +
        formattedNumber
          .slice(1)
          .replace(/\d/g, (digit) => persianDigits[digit]);
    } else {
      persianNumber = formattedNumber.replace(
        /\d/g,
        (digit) => persianDigits[digit]
      );
    }
    return persianNumber;
  }
};
const EnToPrNo = (number) => {
  const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const formattedNumber = number.toString();
  let persianNumber;

  if (formattedNumber.startsWith("-")) {
    persianNumber =
      "-" +
      formattedNumber.slice(1).replace(/\d/g, (digit) => persianDigits[digit]);
  } else {
    persianNumber = formattedNumber.replace(
      /\d/g,
      (digit) => persianDigits[digit]
    );
  }

  return persianNumber;
};
const getPercent = (e) => {
  if (e) {
    const arr = JSON.parse(e);
    if (arr.length < 2) {
      console.log("Array must contain at least two elements.");
      return 0;
    }
    const lastValue = arr[arr.length - 1];
    const secondLastValue = arr[arr.length - 2];
    const number = lastValue / secondLastValue;
    const rounded = number.toFixed(2);
    if (isNaN(rounded)) {
      return 0;
    } else {
      return rounded;
    }
  }
};
function compareLastTwoValues(e) {
  if (e) {
    const arr = JSON.parse(e);
    if (arr.length < 2) {
      console.log("Array must contain at least two elements.");
      return false;
    }
    const lastValue = arr[arr.length - 1];
    const secondLastValue = arr[arr.length - 2];
    return lastValue > secondLastValue;
  }
}
// const setNew = () => {
//   rows.value.push({
//     index: 2,
//     name: "پلی اتیلن سبک خطی",
//     price: 40000,
//     day: -1.1,
//     week: 0.3,
//     mounth: -0.5,
//     basePrice: 2100,
//     finalPrice: 2600,
//     chart: [
//       230447, 384046, 19443, 242745, 537123, 234426, 397120, 222664, 387138,
//       1364, 227931, 166391, 318600, 194079, 508473, 389249, 167197, 64622,
//       33695, 303127,
//     ],
//   });
// };
watch(route, () => {
  getMaterial();
});
onBeforeMount(() => {
  getMaterial();
});
</script>
<style lang="scss" scoped>
.main-container {
  margin-top: 4rem;
  /* padding: 0 20vh; */
  background-color: transparent;
}
.pricedown {
  color: #ea3943;
  svg {
    margin-top: 4px;
    width: 17px;
    height: 17px;
  }
}
.priceup {
  color: #16c784;
  svg {
    margin-top: 4px;
    width: 17px;
    height: 17px;
  }
}
.table-container {
  display: flex;
  justify-content: center;
}
table {
  width: 1100px;
  .table-name {
    margin-left: 5px;
    img {
      border-radius: 100%;
      width: 24px !important;
      height: 24px !important;
    }
  }
  thead {
    tr {
      position: sticky !important;
      top: 0;
      height: 40px;
      line-height: 33px;
      z-index: 5;
      padding: 0 !important;
      font-weight: bold;
      font-size: 14px !important;
      /* border-top: #e0e0e0 1px solid; */
      th {
        /* color: #ecf3fc !important; */
        border: none;
        background-color: #ecf3fc !important;
      }
    }

    .bookmark {
      width: 38px;
    }
    .number {
      width: calc(38px);
    }
    .name {
      width: 238px;
    }
    .price {
      width: 119px;
    }
    .date {
      width: 170px;
    }
    .chart {
      width: 185px;
    }
    .change {
      width: 78px;
    }
  }
  tbody {
    tr {
      &:hover {
        td {
          cursor: pointer;
          background-color: #f8fafd;
        }
      }
    }
  }
  tbody {
    tr {
      height: 64px !important;
      font-weight: bold;
      font-size: 14px !important;
    }
    td {
      /* &:hover {
        background-color: #ecf3fc !important;
      } */
      border: none;
      line-height: 64px;
    }
  }
}
.call-button {
  button {
    font-size: 11px;
  }
}
.table-body-row {
  height: 75px !important;
}
.table-main {
  position: relative;
  border-left: none !important;
  border-right: none !important;
}
.vtc {
  height: 50px;
  width: 100px;
  font-size: 12px;
}
@media only screen and (max-width: 800px) {
  .main-container {
    padding: 0 20px;
  }
  table {
    width: 100%;
    .table-name {
      margin-left: 5px;
      img {
        width: 24px !important;
        height: 24px !important;
      }
    }
    thead {
      tr {
        position: sticky !important;
        top: 70px;
        height: 40px;
        line-height: 33px;
        font-weight: bold;
        font-size: 10px !important;
        th {
          /* color: #ecf3fc !important; */
          background-color: #ecf3fc !important;
        }
      }

      .bookmark {
        width: 38px;
      }
      .number {
        width: calc(38px);
      }
      .name {
        width: 238px;
      }
      .price {
        width: 112px;
      }
      .date {
        width: 170px;
      }
      .chart {
        display: none;
        width: 185px;
      }
      .change {
        width: 78px;
      }
    }
    tbody {
      tr {
        &:hover {
          td {
            cursor: pointer;
            background-color: #f8fafd;
          }
        }
      }
    }
    tbody {
      tr {
        height: 64px !important;
        font-weight: bold;
        font-size: 12px !important;
      }
      td {
        /* &:hover {
        background-color: #ecf3fc !important;
      } */
        line-height: 64px;
      }
    }
  }
  .pricedown {
    color: #ea3943;
    svg {
      margin-top: 4px;
      width: 14px;
      height: 14px;
    }
  }
  .chart-container {
    display: none;
  }
  .priceup {
    color: #16c784;
    svg {
      margin-top: 4px;
      width: 14px;
      height: 14px;
    }
  }
}
@media only screen and (max-width: 430px) {
  .main-container {
    padding: 0 10px;
  }
  table {
    width: 100%;
    .table-name {
      margin-left: 5px;
      img {
        width: 20px !important;
        height: 20px !important;
      }
    }
    thead {
      tr {
        position: sticky !important;
        top: 70px;
        height: 30px;
        line-height: 23px;
        font-weight: bold;
        font-size: 8px !important;
      }
      .bookmark {
        width: 20px;
      }
      .number {
        width: calc(30px);
      }
      .name {
        width: 200px;
      }
      .price {
        width: 100px;
      }
      .date {
        width: 130px;
      }
      .chart {
        width: 155px;
      }
      .change {
        width: 58px;
      }
    }
    tbody {
      tr {
        &:hover {
          td {
            cursor: pointer;
            background-color: #f8fafd;
          }
        }
      }
    }
    tbody {
      tr {
        height: 44px !important;
        font-weight: bold;
        font-size: 10px !important;
      }
      td {
        /* &:hover {
        background-color: #ecf3fc !important;
      } */
        line-height: 44px;
      }
    }
  }
  .pricedown {
    color: #ea3943;
    svg {
      margin-top: 4px;
      width: 11px;
      height: 11px;
    }
  }
  .priceup {
    color: #16c784;
    svg {
      margin-top: 4px;
      width: 11px;
      height: 11px;
    }
  }
}
</style>
